.containerCart {
  display: flex;
  width: 97%;
  height: 48px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  margin-left: 5px;
  margin-right: 5px;
  max-width: 490px;
  bottom: 0;
  z-index: 30;
  &:active {
    opacity: 0.7;
  }
}

.containerCart2 {
  display: flex;
  width: 97%;
  height: 48px;
  border-radius: 8px;
  justify-content: center;
  padding: 0px 20px;
  margin-left: 5px;
  margin-right: 5px;
  max-width: 485px;
  z-index: 30;
  bottom: 0;
}

.imgCart {
  width: 25px;
  height: 25px;
  align-self: center;
}

.text {
  color: white;
  align-self: center;
  font-weight: 700;
  font-size: 18px;
  margin-left: 25px;
}

.text2 {
  color: white;
  align-self: center;
  font-weight: 700;
  font-size: 18px;
}

.price {
  color: white;
  align-self: center;
  font-weight: 600;
  font-size: 18px;
  margin-left: 25px;
}
.container {
  position: fixed;
  background-color: white;
  height: 70px;
  width: 100%;
  bottom: 0;
  justify-content: center;
  padding-top: 12px;
  border-top: 1px solid #f4e6e6;
  max-width: 490px;
  z-index: 30;
}

.container2 {
  position: fixed;
  background-color: white;
  height: 70px;
  width: 100%;
  bottom: 0;
  align-items: flex-end;
  padding-top: 12px;
  border-top: 1px solid #f4e6e6;
  max-width: 490px;
  z-index: 30;
}

.container3 {
  position: fixed;
  display: flex;
  justify-content: space-around;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 110px;
  width: 100%;
  bottom: 0;
  padding-top: 12px;
  border-top: 1px solid #f4e6e6;
  max-width: 488px;
  z-index: 30;
  align-items: flex-start;
}
