.containerText {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  padding: 0px 15px;
}
.titleCart {
  font-size: 32px;
  font-family: sans-serif;
  font-weight: 900;
  color: black;
}

.containerCard {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.containerCard2 {
  display: flex;
  justify-content: center;
}


.containerItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
}

.flexItems {
  display: flex;
  justify-content: space-around;
  padding: 0 18px;
}
.itemQuantity {
  font-size: 17px;
  font-weight: 600;
  font-family: Sans-serif;
  color: black;
}
.itemWeighing {
  font-size: 18px;
  font-weight: 300;
  font-family: Sans-serif;
  margin-left: 3px;
  align-self: center;
}

.itemName {
  font-weight: 400;
  font-size: 15px;
  font-family: Sans-serif;
  text-align: start;
  width: 200px;
  width: 100%;
}
.itemSymbol {
  font-size: 16px;
  font-weight: 600;
  font-family: Sans-serif;
  margin-left: 3px;
  color: black;
  align-self: center;
}
.itemPrice {
  font-size: 20px;
  font-weight: 900;
  font-family: Sans-serif;
  color: black;
  white-space: nowrap;
  > sub{
    bottom: 0;
  }
}

.lineSeparator {
  height: 1.5px;
  background-color: #f3f3f3;
  width: 80%;
  align-self: center;
}
.containerTotalPrice {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 16px;
}
.containerTotalPrice2 {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  padding-right: 16px;
}
.containerTotalPrice3 {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  padding-right: 16px;
  margin-top: 30px;
}
.totalText {
  font-weight: 600;
  font-family: sans-serif;
  font-size: 20px;
  align-self: center;
  margin-right: 15px;
}
.totalTextDiscount {
  font-weight: 00;
  font-family: sans-serif;
  font-size: 14px;
  align-self: center;
  margin-right: 15px;
  color: rgb(88, 88, 88);
}
.totalNumber {
  font-weight: 900;
  font-family: sans-serif;
  font-size: 26px;
  color: black;
}
.totalDiscount {
  font-weight: 700;
  font-family: sans-serif;
  font-size: 16px;
  color: rgb(88, 88, 88);
  padding: 0 1%;
}
.imgRestar {
  background-color: white;
  width: 8px;
  height: 2px;
}

.imgSumar {
  width: 10px;
  height: 10px;
}
.quantityCount {
  align-self: center;
  font-size: 20px;
  font-weight: 900;
  margin-left: 10px;
  margin-right: 10px;
}

.containerButton {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 30px;
  height: 35px;
}
.button {
  border-radius: 20px;
  width: 75%;
  background-color: #3ec487;
  border: none;
  color: white;
  font-size: 16;
  font-weight: 400;
}


.cartItemsList{
  flex-direction: column;
  > li{
    padding: 10px;
    border-bottom: thin solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .itemImageAndName{
      display: flex;
      flex-direction: row;
      flex: 2;
    }
  }
}

.quantityControlContainer{
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 0 10px;
  align-items: center;
  justify-content: center;
}
.priceAndTrashContainer{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.itemImage{
  margin-right: 10px;
  align-self: flex-start;
  @media (max-width: 400px) {
    display: none;
}
}

.itemDeleteButton{
  margin-top: 5px;
  padding: 5px ;
  border: thin solid #eee;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  img{
    width: 15px;
    margin-right: 5px;
    margin-top: -5px;
  }
  &:hover{
    color: red;
    border-color: red;
  }
}
