.containerArrow {
  padding: 20px 20px 0px 20px;
}
.container {
  position: relative;
  padding-bottom: 80px;
}
.containerArrow:active {
  opacity: 0.5;
  image {
    opacity: 0.5;
  }
}

.containerArrow2 {
  padding: 20px 20px 0px 20px;
  position: fixed;
  z-index: 3;
  top: 0;
}
.containerArrow2:active {
  opacity: 0.5;
  image {
    opacity: 0.5;
  }
}

.wrapperDiscountAndGallery{
  position: relative;
  margin: 10px 15px;
}

.containerImageGallery{
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerTitleAndPrice {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0px 20px;
}

.title {
  font-size: 28px;
  font-weight: 600;
  font-family: sans-serif;
  color: black;
}
.containerPrice {
  flex-direction: column;
  margin-top: 20px;
}

.symbol {
  font-size: 18px;
  font-weight: 500;
  font-family: sans-serif;
  font-weight: 600;
  color: black;
  align-self: center;
}
.price {
  font-size: 30px;
  font-weight: 900;
  font-family: sans-serif;
  color: black;
  margin-left: 2px;
}
.price2 {
  font-size: 32px;
  font-weight: 900;
  font-family: sans-serif;
  color: black;
  margin-left: 2px;
}

.youSaveMessage{
  padding: 0 0 10px 0;
  color:  #ef192a;
}

.quantity {
  background-color: #ff7500;
  color: white;
  font-size: 18px;
  font-weight: 900;
  font-family: sans-serif;
  padding: 5px;
}

.quantityText {
  background-color: #ef192a;
  color: #f9ef09;
  font-size: 12px;
  font-weight: 900;
  font-family: sans-serif;
  padding: 5px;
}
.cardInfo {
  margin-top: 30px;
  padding: 0px 20px 0px 20px;
}
.containerQuantitySelection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
}
.quantityTitle {
  font-size: 22px;
  font-weight: 500;
  font-family: sans-serif;
  color: black;
  align-self: center;
}
.quantityTitle2 {
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
color: #011D0E;
  align-self: flex-start;
  margin-left: 22px;
  line-height: 26px;
}
.containerButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  padding: 15px 20px 0px 15px;
}
.imgRestar {
  background-color: white;
  width: 18px;
  height: 5px;
}
.quantitySelection {
  font-size: 30px;
  font-weight: 900;
  font-family: sans-serif;
  color: black;
  margin-left: 2px;
  align-self: center;
}
.imgSumar {
  width: 23px;
  height: 23px;
}
.containerTotal {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  background-color: white;
  width: 100%;
}
.totalText {
  align-self: center;
  font-family: sans-serif;
  font-weight: 600;
  margin-right: 10px;
  font-size: 18px;
}
.symbolTotal {
  font-size: 20px;
  font-weight: 900;
  font-family: sans-serif;
  color: black;
  align-self: center;
}
.total {
  font-size: 40px;
  font-weight: 900;
  font-family: sans-serif;
  color: black;
  margin-left: 2px;
}

.buttonDisabled {
  background-color: #bdbdbd;
  border: 1px solid #bdbdbd;
}
.buttonDisabled2 {
  background-color: #bdbdbd;
  border: 1px solid #bdbdbd;
  color: white;
  border-radius: 100%;
  width: 42px;
  height: 40px;
  margin-left: 10px;
}

.concat {
  border-radius: 50%;
  width: 20px;
  margin-left: 10px;
}
.iconBack {
  width: 40px;
}
.titleAndPrice {
  display: flex;
  flex-direction: column;
  max-width: 80%;
}
.priceSize {
  font-size: 19px;
}
.flexCol {
  display: flex;
  flex-direction: column;
}
.promo {
  width: 97px;
  height: 46px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 18px;
  background:  #FFED1D;
  /* Drop Shadow Small */
  box-shadow: 0px 3px 10px rgba(61, 66, 70, 0.1);
  border-radius: 40px 0px 0px 40px;
}

.promoMayor {
  width: 97px;
  height: 55px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 18px;
  background:  #FFED1D;
  box-shadow: 0px 3px 10px rgba(61, 66, 70, 0.1);
  border-radius: 40px 0px 0px 40px;
}

.porcentaje {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 16px;
  margin-left: 8px;
  /* or 64% */
  text-align: center;

  color: #000;
}
.oldPrice {
  text-decoration-line: line-through;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  /* or 93% */
  margin-right: 2px;
  letter-spacing: 0.25px;
  color: #4F545C;
}

.porcentajeMayor {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;

  /* or 64% */
  text-align: center;
  color: #000;
  z-index: 1;
}

.porcentajeMenor {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 16px;
  text-align: center;
  color: #000;
  top: 24px;
  right: 18px;
  z-index: 1;
}

.containerTextInsuperable{
  background: #FFEC3E;
  padding: 5px;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  display: flex;
  align-self: flex-start;
 }

 .discountBadge{
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
}


.discountStarMayor{
  width: 80px;
  height: 80px;
}
.discountStarMenor{
  width: 56px;
  height: 56px;
}

.discountStarMayor,.discountStarMenor{

}

.textAhorroBig{
  font-family: sans-serif;
  font-style: normal;
  font-size: 9.5px;
  text-align: center;
color: #011D0E;
  top: 55px;
  right: 29px;
  z-index: 1;
  align-self: center;
  font-weight: 600;
  line-height: 10px;
}

.textAhorroSmall{
  font-family: sans-serif;
  font-style: normal;
  font-size: 7px;
  text-align: center;
color: #011D0E;
  top: 42px;
  right: 22px;
  z-index: 1;
  align-self: center;
  font-weight: 600;
}

.signoMayor{
  font-size: 20px;
  font-weight: 500;
}

.signoMenor{
  font-size: 16px;
  font-weight: 500;
}

.containerDescription{
  padding: 20px;
}
.whatsappShareButton{
  position: absolute;
  display: flex;
  padding: 10px;
  border-radius: 5px;
  background-color: #25D366;
  z-index: 1;
  div{
    color: white;
    font-size: 14px;
    margin-left: 5px;
  }
  svg{
    fill: white;
  }
  &:hover{
    background-color: #075E54
  }
}