.container {
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
}
.containerB2b {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  margin-top: 10px;
}
.container2 {
  min-height: 680px;
  padding-bottom: 60px;
  background-color: #FAFBFA;
}
.customCard {
  height: 100%;
  display: flex;
  background: #FFFFFF;
  border: 2px solid #F6F6F7;
  box-shadow: 0px 4px 10px rgba(48, 54, 64, 0.02);
  border-radius: 12px;
}
.customCard:active {
  background-color: #FAFBFA;
  opacity: 0.3;
  image {
    background-color: #FAFBFA;
    opacity: 0.3;
  }
}



.opacity{

}
.opacity:active {
  background-color: #FAFBFA;
  opacity: 0.3;
  image {
    background-color: #FAFBFA;
    opacity: 0.3;
  }
}

.customCard2 {
  min-height: 100%;
  display: flex;
  border-radius: 0.75rem;
  background: #FFFFFF;
  border: 2px solid #F6F6F7;
  box-shadow: 0px 4px 10px rgba(48, 54, 64, 0.02);
  border-radius: 12px;
  justify-content: space-between;
}

.titleSection {
  padding-top: 20px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
}
.cardId {
  border-radius: 6px;
  height: 100%;
}

.cardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  align-items: stretch;
  width: 90%;
  margin: 0 auto;
  background-color: #FAFBFA;
  padding: 10px 0px 30px 0px;
}
.containerCards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  align-items: stretch;
  width: 90%;
  margin: 0 auto;
  padding: 30px 0px 30px 0px;
}
.cardsContainer22 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  align-items: stretch;
  width: 90%;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 20px 0px 30px 0px;
}
.cardsContainerB2b {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  align-items: stretch;
  width: 90%;
  margin: 0 auto;
  background-color: #FAFBFA;
  padding: 30px 0px 30px 0px;
}

.cardsContainerScroll {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  align-items: stretch;
  width: 90%;
  margin: 0 auto;
  background-color: #FAFBFA;
  padding: 140px 0px 30px 0px;
}

.cardsContainer2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  align-items: stretch;
  width: 95%;
  margin: 0 auto;
  background-color: #FAFBFA;
  padding: 30px 2px 30px 2px;
}

.cardContainer {
  width: 140px;
  border-radius: 6px;
  padding-top: 5px;
}

.cardTitle {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  margin-top: 5px;
}
.cardTitle2 {
  text-align: center;
  font-weight: 800;
  font-size: 14.5px;
  padding-bottom: 8px;
}
.cardTitleCategory {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  margin-top: 4px;
  font-family: sans-serif;
}

.cardTitleCategory2 {
  /* Fonts/Bold Small */
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  /* or 107% */
  /* Grayscale / Label */
  color: #576875;
}
.cardOffer {
  padding: 2px;
  margin-top: 10px;
  background-color: #bc001c;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  color: white;
}

.cardNoOffer2 {
  padding: 2px;
  margin-top: 5px;
}

.CardSpace {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 15px 0px;
}

.CardSpace3 {
  min-height: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cardSize {
  height: 160px;
  width: 140px;
}
.noProducts {
  display: flex;
  justify-content: center;
  margin-top: 40%;
  align-self: center;
  padding: 0px 20px;
}
.noProductsText {
  font-size: 20px;
  text-align: center;
  font-family: sans-serif;
}
.CardSpace2 {
  margin: 10px 0px;
}
.containerImg {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 7px;
}
.containerTextCard {
  justify-content: center;
  padding: 0px;
}
.textFlex {
  display: flex;
  flex-direction: column;
}
.subtitle {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  margin-top: 5px;
  /* or 127% */

  /* Grayscale / Placeholder */
  color: #9eaeba;
}
.priceAndSymbol {
  display: flex;
  flex-direction: column;
  padding: 7px 15px 10px 15px;
  align-items: flex-start;
}
.symbol {
  font-size: 15px;
  font-weight: 600;
  font-family: sans-serif;
  color: black;
  align-self: center;
  line-height: 30px;
}
.price {
  font-size: 19px;
  font-weight: 900;
  font-family: sans-serif;
  color: black;
  align-self: flex-start;
}
.containerImgList {
  display: flex;
  justify-content: center;
  width: 80%;
  align-self: center;
}
.backgroundList {
  margin-top: 24px;
}
.promo {
  position: absolute;
  z-index: 1;
  width: 52px;
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 0px;
  top: 20px;
  background: #FFED1D;
  /* Drop Shadow Small */
  box-shadow: 0px 3px 10px rgba(61, 66, 70, 0.1);
  border-radius: 40px 0px 0px 40px;
}
.porcentaje {
  /* Fonts/Bold Small */
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 16px;
  /* or 107% */
  text-align: center;
  margin-left: 6px;
  color: #000;
}
.descuento {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 7px;
  line-height: 16px;
  /* or 267% */
  text-align: center;
  margin-left: 5px;
  color: #ffffff;
}
.preciotachado {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: .25px;
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
  color: silver;
  text-align: start;
}

.discountStarMayor{
  position: absolute;
  z-index: 1;
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 2px;
  top: 2px;
}
.discountStarMenor{
  position: absolute;
  z-index: 1;
  width: 44px;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 6px;
  top: 6px;
  align-items: center;
}

.porcentajeMayor {
  position: absolute;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 16px;
  text-align: center;
  color: #011D0E;
  top: 22px;
  z-index: 2;
  right: 12px;
}

.porcentajeMenor {
  position: absolute;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #011D0E;
  top: 19px;
  right: 12.5px;
  z-index: 2;
  align-self: center;
}
.containerTextInsuperable{
  width: auto;
  height: 14px;
  background: #FFEC3E;
  padding: 0px 2px;
  margin-top: 5px;
 }

.textInsuperable{
    font-family: sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #011D0E;
}

.textAhorroBig{
  position: absolute;
  font-family: sans-serif;
  font-style: normal;
  font-size: 9px;
  text-align: center;
  color: #011D0E;
  top: 40px;
  right: 13.5px;
  z-index: 2;
  align-self: center;
  font-weight: 600;
  line-height: 10px;
}

.textAhorroSmall{
  position: absolute;
  font-family: sans-serif;
  font-style: normal;
  font-size: 5px;
  text-align: center;
  color: #011D0E;
  top: 30.5px;
  right: 17px;
  z-index: 2;
  align-self: center;
  font-weight: 600;
}

.signoMayor{
  font-size: 16px;
  font-weight: 500;
}

.signoMenor{
  font-size: 12px;
  font-weight: 500;
}