.containerDiv {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #FF6625;
}
.containerImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: white;
    margin-top: 15px;
  }
}
.sizeImg {
  width: 130px;
}
.containerBar {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 80px;
}
