.container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 16px;
  text-align: center;
  color: #576875;
  margin-top: 30px;
}

.subtitle {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  color: #576875;
  margin-top: 20px;
  line-height: 20px;
}

.button {
  width: 214px;
  height: 45px;
  left: 287px;
  top: 445px;
  background: #6ec392;
  box-shadow: 0px 3px 10px rgba(61, 66, 70, 0.1);
  border-radius: 40px;
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.button:active {
  opacity: 0.6;
}

.textButton {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}
