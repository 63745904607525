@import url('https://fonts.google.com/specimen/Patrick+Hand?query=patrick+hand');

.containerLogoPerfil {
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background: white;
  position: absolute;
  left: 34%;
  top: 4%;
  height: 130px;
  width: 130px;
  background-color: white;
}
.contenedor {
  margin: 0 auto;
  position: relative;
  display: flex;
  background-color: white;
  border-radius: 50%;
  width: 120px;
  z-index: 51;
}

.textTitle {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: black;
}
.textTitleName {
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  color: black;
  font-family: sans-serif;
}
.containerRedes {
  display: flex;
  justify-content: center;
  height: 35px;
  margin-top: 20px;
  background-color: white;
}
.containerMessage {
  padding: 20px;
  margin-top: 7px;
}
.imgPortada {
  height: auto;
}
.containerImgPerfil {
  width: 128px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5vh;
}
.imgPerfil {
  margin: 0 auto;
  border-radius: 50%;
  width: auto;
  position: absolute;
}
.logosRedes {
  padding-right: 5px;
  width: 45px;
}
.message {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
}
.speach {
  text-align: center;
  font-size: 13px;
}
.text {
  text-align: center;
  font-size: 17px;
}
